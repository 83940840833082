import { useContext } from 'react';

import { TrackPreviewContext } from '@/contexts/TrackPreviewContext';

const usePlayTrackPreview = () => {
  const context = useContext(TrackPreviewContext);
  if (!context) {
    throw new Error('usePlayTrackPreview must be used within a TrackPreviewContextProvider');
  }
  return context;
};

export default usePlayTrackPreview;
