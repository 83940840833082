import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ReviewsOverTimeModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useAccount from '../useAccount';

const useReviewsOverTime = () => {
  const { unhurdAccountId } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ReviewsOverTimeModel> | null>(
    () => {
      if (!unhurdAccountId) return null;

      return `reviews-over-time-data-${unhurdAccountId}`;
    },
    async () => {
      if (!unhurdAccountId) return null;

      return await MetricsAPI.getReviewsOverTime({
        unhurdId: unhurdAccountId,
      });
    }
  );

  return {
    reviewsOverTime: data?.data.stats,
    totalReviewsOverTime: data?.data.total,
    reviewsOverTimeIsLoading: isLoading,
    reviewsOverTimeError: error,
    refetchReviewsOverTime: mutate,
  };
};

export default useReviewsOverTime;
