import { useEffect, useState } from 'react';

import usePlaylistStats from './metrics/usePlaylistStats';
import useAccount from './useAccount';

type TodoData = {
  payPalConnected: boolean | string;
  hasPlaylists: boolean;
};

const useTodos = () => {
  const { unhurdUser } = useAccount();
  const { connectedPlaylists } = usePlaylistStats();
  const [todoData, setTodoData] = useState<TodoData>();

  useEffect(() => {
    if (!unhurdUser || !connectedPlaylists) return;
    const todoData = {
      payPalConnected: unhurdUser.paymentDetails?.payPal?.email ?? false,
      hasPlaylists: connectedPlaylists > 0,
    };
    setTodoData(todoData);
  }, [unhurdUser, connectedPlaylists]);

  return {
    payPalConnected: todoData?.payPalConnected,
    hasPlaylists: todoData?.hasPlaylists,
  };
};

export default useTodos;
