import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { User } from '@/models/UserInterfaces';
import UserAPI from '@/network/UserAPI';

const useUser = ({ id }: { id?: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<User> | null>(
    () => {
      if (!id) return null;

      return `user-account-data-${id}`;
    },
    async () => {
      if (!id) return null;
      if (
        axios.defaults.headers.common.Authorization === '' ||
        axios.defaults.headers.common.Authorization === undefined
      )
        return null;

      return await UserAPI.getUserByID({
        id: id,
      });
    }
  );

  return {
    unhurdUserAccount: data?.data,
    userIsLoading: isLoading,
    userError: error,
    refetchUser: mutate,
  };
};

export default useUser;
