import usePlaylists from './playlists/usePlaylists';
import useTransactions from './transactions/useTransactions';
import useAccount from './useAccount';

export const useRefetchAfterReview = () => {
  const { refetchPlaylists } = usePlaylists({
    pageNumber: 1,
    sortBy: 'name',
    orderBy: 'asc',
    status: 'pending',
  });
  const { refetchUser } = useAccount();
  const { refetchTransactions } = useTransactions({
    status: '',
    pageSize: 20,
  });

  return {
    refetchAfterReview: async () => {
      await refetchPlaylists();
      await refetchUser();
      await refetchTransactions();
    },
  };
};
