import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TransactionInterface } from '@/models/TransactionInterface';
import TransactionsAPI from '@/network/TransactionsAPI';

import useAccount from '../useAccount';

const useTransactions = ({
  status,
  pageSize,
  contToken,
}: {
  status: string;
  pageSize?: number;
  contToken?: string;
}) => {
  const { unhurdAccountId } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TransactionInterface> | null>(
    () => {
      if (!unhurdAccountId) return null;

      return `transactions-data-${unhurdAccountId}-${status}-${contToken}-${pageSize ?? 20}`;
    },
    async () => {
      if (!unhurdAccountId) return null;

      return await TransactionsAPI.getTransactions({
        id: unhurdAccountId,
        pageSize: pageSize ?? 20,
        status: status,
        contToken: contToken,
      });
    }
  );

  return {
    transactions: data?.data.items,
    totalTransactions: data?.data.total,
    transactionsContToken: encodeURIComponent(data?.data.continuationToken || ''),
    transactionsIsLoading: isLoading,
    transactionsError: error,
    refetchTransactions: mutate,
  };
};

export default useTransactions;
