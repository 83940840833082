import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { User } from '@/models/UserInterfaces';
import { initApi, initToken } from '@/utility/api';

import useUser from './useUser';

const useAccount = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [account, setAccount] = useState<User | null>(null);
  const [accountLoading, setAccountLoading] = useState<boolean>(true);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const { unhurdUserAccount, userIsLoading, userError, refetchUser } = useUser({
    id: user?.sub,
  });

  const fetchAccount = useCallback(async () => {
    if (userError) {
      setAccount(null);
      setAccountLoading(false);
    }
    if (unhurdUserAccount) {
      setAccount(unhurdUserAccount);
      setAccountLoading(false);
    }
  }, [unhurdUserAccount, userError]);

  const getAccessToken = useCallback(async () => {
    getAccessTokenSilently().then(async (resp) => {
      await initToken(resp);
      await initApi(import.meta.env.VITE_API_URL);
      if (isInitialLoad && !unhurdUserAccount && !userIsLoading && !userError && user) {
        await refetchUser();
      }
      if (
        (user && !user?.email_verified && !unhurdUserAccount && !userIsLoading) ||
        (unhurdUserAccount?.accountConfiguration.onboardingStage &&
          unhurdUserAccount?.accountConfiguration.onboardingStage < 5)
      ) {
        if (location.pathname.includes('paypal-callback')) return;
        navigate('/onboarding');
      }
      setIsInitialLoad(false);
    });
  }, [
    getAccessTokenSilently,
    isInitialLoad,
    location.pathname,
    navigate,
    refetchUser,
    unhurdUserAccount,
    user,
    userError,
    userIsLoading,
  ]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) return navigate('/login');
    if (user && isAuthenticated && !unhurdUserAccount && isInitialLoad) {
      getAccessToken();
    }
    fetchAccount();
  }, [user, isAuthenticated, isLoading, navigate, getAccessToken, fetchAccount, unhurdUserAccount, isInitialLoad]);

  return {
    unhurdUser: account,
    unhurdAccountId: account?.id,
    accountLoading: accountLoading,
    userUnauthorized: !isLoading && !accountLoading && !isAuthenticated && user === null && account === null,
    accountError: !accountLoading && account === null,
    refetchUser: refetchUser,
  };
};

export default useAccount;
