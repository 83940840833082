import { useEffect, useState } from 'react';

const useScreenBreakPoint = () => {
  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);

  const handleResize = () => {
    window.innerWidth <= 900 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  return {
    breakPointHit: breakPointHit,
  };
};

export default useScreenBreakPoint;
