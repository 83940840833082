import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PendingPitchesStatsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useAccount from '../useAccount';

const usePendingPitchesStats = () => {
  const { unhurdAccountId } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PendingPitchesStatsModel> | null>(
    () => {
      if (!unhurdAccountId) return null;

      return `pending-pitches-stats-data-${unhurdAccountId}`;
    },
    async () => {
      if (!unhurdAccountId) return null;

      return await MetricsAPI.getPendingPitchesStats({
        unhurdId: unhurdAccountId,
      });
    }
  );

  return {
    distinctArtists: data?.data.distinctArtists,
    distinctTracks: data?.data.distinctTracks,
    pendingPitches: data?.data.pendingPitches,
    pendingPitchesStatsIsLoading: isLoading,
    pendingPitchesStatsError: error,
    refetchPendingPitchesStats: mutate,
  };
};

export default usePendingPitchesStats;
