import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlaylistPitchesAPIModel } from '@/models/TrackInterfaces';
import PitchesAPI from '@/network/PitchesAPI';

import useAccount from '../useAccount';

const useAllPitches = ({
  status,
  pageSize,
  contToken,
  sortedBy = 'pending',
  orderedBy = 'DESC',
}: {
  status: string;
  pageSize?: number;
  contToken?: string;
  sortedBy?: string;
  orderedBy?: string;
}) => {
  const { unhurdAccountId } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlaylistPitchesAPIModel> | null>(
    () => {
      if (!unhurdAccountId) return null;

      return `playlist-pitches-data-${unhurdAccountId}-${status}-${contToken}-${pageSize ?? 20}-sortedBy${sortedBy}-orderedBy${orderedBy}`;
    },
    async () => {
      if (!unhurdAccountId) return null;

      return await PitchesAPI.getAllPitches({
        accountId: unhurdAccountId,
        pageSize: pageSize ?? 20,
        status: status,
        contToken: contToken,
        sortedBy: sortedBy,
        orderedBy: orderedBy,
      });
    }
  );

  return {
    pitches: data?.data.items,
    totalPitches: data?.data.itemCount,
    pitchesContToken: data?.data.urlEncodedContinuationToken,
    pitchesIsLoading: isLoading,
    pitchesError: error,
    refetchPitches: mutate,
  };
};

export default useAllPitches;
